import CreateDealerApplication from '~/components/modal-windows/CreateDealerApplication.vue'
// import CreateSubscriber from '~/components/modal-windows/CreateSubscriber.vue'
import constants from '~/constants'
import Page from '~/enums/page'
import type { Banner, BannerCard } from '~/types/banners'

export const bannerCards: BannerCard[] = [
  {
    buttonText: 'Подробнее',
    description: 'Составление программ и\xA0подбор БАД',
    name: 'Консультации специалистов',
    path: constants.categories.consultations.path
  },
  {
    buttonText: 'Пройти тест',
    description: 'Пройдите ИИ-тест и\xA0получите подборку БАД',
    name: 'Подбор БАД',
    path: Page.Tests
  }
]

export const banners1: Banner[] = [
  {
    backgroundColor: '#f87a37',
    buttonColor: 'white',
    buttonTitle: 'Купить',
    color: 'white',
    description: 'Вместе с\xA0линейкой профессиональных добавок Biomins\n\n'
      + 'Оцените первыми синергичные составы Biomins, работающие на\xA0результат',
    path: `${Page.Brands}biomins-321/`,
    picture: { src: '/images/banners/74.jpg', src2: '/images/banners/74_small.jpg' },
    title: 'Раскроем ваш потенциал'
  }
  // {
  //   backgroundColor: '#ffba00',
  //   buttonColor: 'white',
  //   buttonTitle: 'Купить',
  //   color: '#404e5e',
  //   description: 'Таурин\xA0— аминокислота, которая способствует улучшению углеводного обмена, поддерживает сердечно-сосудистую систему, избавляет от\xA0отёков и\xA0заряжает энергией!',
  //   path: `${Page.Products}supermins-taurin-kapsuly-60-sht-2997/`,
  //   picture: { src: '/images/banners/71.jpg', src2: '/images/banners/71_small.jpg' },
  //   title: 'Новинка от\xA0Supermins'
  // }
  // {
  //   backgroundColor: '#10395f',
  //   buttonColor: 'white',
  //   buttonTitle: 'Купить билет',
  //   color: 'white',
  //   description: 'Приглашаем на\xA0мероприятие, посвященное мужскому и\xA0женскому планированию беременности и\xA0I\xA0триместру. Для вас выступят Владимир Попов, Дмитрий Семирядов и\xA0Элисо Джобава.\n\n'
  //     + 'По\xA0промокоду iTAB10 дарим скидку\xA010% на\xA0билеты',
  //   path: 'https://biohackia.ru/creators-p1',
  //   picture: { src: '/images/banners/75.jpg', src2: '/images/banners/75_small.jpg' },
  //   title: 'Узнайте, как стать «Создателями»'
  // },
  // {
  //   backgroundColor: '#3fa4be',
  //   buttonColor: 'white',
  //   buttonTitle: 'Перейти',
  //   color: '#ffffff',
  //   description: 'Настало время для яркого солнца, отпуска и\xA0незабываемых эмоций! Для того, чтобы чувствовать себя комфортно даже в\xA0жаркую погоду, мы\xA0собрали любимые летние продукты в\xA0одной категории',
  //   path: constants.categories.sunnySummer.path,
  //   picture: { src: '/images/banners/60.jpg', src2: '/images/banners/60_small.jpg' },
  //   title: 'Солнечное лето'
  // }
  // {
  //   backgroundColor: '#eb480f',
  //   buttonColor: 'white',
  //   buttonTitle: 'За покупками',
  //   color: '#ffffff',
  //   description: 'Оформляйте заказы на\xA0iTAB и\xA0участвуйте в\xA0розыгрыше ценных призов\xA0— ноутбуков, школьной формы, наборов добавок',
  //   path: `${Page.SpecialOffers}uchastvuyte-v-rozygryshe-back2school-54/`,
  //   // picture: { src: '/images/banners/72.jpg', src2: '/images/banners/72_small.jpg' },
  //   picture: { src: '/images/banners/73.jpg', src2: '/images/banners/73_small.jpg' },
  //   title: 'Готовимся к\xA0школе'
  // },
  // {
  //   backgroundColor: '#faf1c5',
  //   buttonColor: 'white',
  //   buttonTitle: 'Хочу купить',
  //   color: '#015811',
  //   description: 'Встречайте ограниченную партию Вкусного коллагена «Ананас» в\xA0новой упаковке.\n\nУспеют забрать только самые быстрые!',
  //   path: `${Page.Products}wolfsportitab-vkusnyy-kollagen-ananas-shoty-12h50-ml-163/`,
  //   picture: { src: '/images/banners/70.jpg', src2: '/images/banners/70_small.jpg' },
  //   title: 'Перевоплощение коллагена'
  // },
  // {
  //   backgroundColor: '#ffbc03',
  //   buttonColor: 'white',
  //   buttonTitle: 'Купить',
  //   color: '#404e5e',
  //   description: 'Попробуйте долгожданные продукты: NAC, Хрома пиколинат, Тиро-йод, Коэнзим Q10',
  //   path: `${Page.Brands}supermins-204/`,
  //   picture: { src: '/images/banners/62.jpg', src2: '/images/banners/62_small.jpg' },
  //   title: 'Новинки от\xA0Supermins'
  // },
  // {
  //   backgroundColor: '#51aa35',
  //   buttonColor: 'white',
  //   buttonTitle: 'За покупками',
  //   color: 'white',
  //   description: 'Зарегистрируйтесь на\xA0платформе iTAB и\xA0получите 300 бонусных рублей, которые можно потратить уже на\xA0первый заказ!',
  //   path: Page.Products,
  //   picture: { src: '/images/banners/69.jpg', src2: '/images/banners/69_small.jpg' },
  //   title: 'Дарим 300 бонусов за\xA0регистрацию'
  // },
  // {
  //   backgroundColor: '#fe0100',
  //   buttonColor: 'white',
  //   buttonTitle: 'Купить',
  //   color: '#ffffff',
  //   description: 'Проводим полную ликвидацию брендов Swiss Bork и\xA0РНА. Скидки до\xA040%',
  //   path: constants.categories.liquidation.path,
  //   picture: { src: '/images/banners/61.jpg', src2: '/images/banners/61_small.jpg' },
  //   title: 'Распродаём остатки'
  // },
  // {
  //   backgroundColor: '#e84665',
  //   buttonColor: 'white',
  //   buttonTitle: 'Смотреть все',
  //   color: '#ffffff',
  //   description: 'Летом хочется брать от\xA0жизни максимум: гулять, заниматься спортом, проводить время с\xA0семьей. Дефициту энергии здесь не\xA0место!\n\nДля вас\xA0— скидки на\xA0товары для устранения усталости и\xA0заряда энергии',
  //   path: constants.categories.summerEnergy.path,
  //   picture: { src: '/images/banners/67.jpg', src2: '/images/banners/67_small.jpg' },
  //   title: 'Успейте все!'
  // },
  // {
  //   backgroundColor: '#b50b19',
  //   buttonColor: 'white',
  //   buttonTitle: 'Купить за\xA01\xA0₽',
  //   color: '#ffffff',
  //   description: 'Соберите корзину любимых товаров на\xA07000\xA0₽ и\xA0добавьте к\xA0заказу Витамин B12 от\xA0Biomins, и\xA0стоимость продукта уменьшится до\xA01\xA0₽',
  //   path: `${Page.Products}biomins-vitamin-b12-aktiv-2-v-1-metil-adenozilkobalamin-zhidkost-100-ml-2785/`,
  //   picture: { src: '/images/banners/68.jpg', src2: '/images/banners/68_small.jpg' },
  //   title: 'Ловите момент!'
  // },
  // {
  //   backgroundColor: '#ffc2cb',
  //   buttonColor: 'white',
  //   buttonTitle: 'Смотреть все',
  //   color: '#6a3d38',
  //   description: 'Специальные цены на\xA0товары для кошек и\xA0собак: корм, вкусняшки, добавки и\xA0средства гигиены\n\nГав! Хозяин, это мы\xA0покупаем!',
  //   path: constants.categories.forAnimals.path,
  //   picture: { src: '/images/banners/64.jpg', src2: '/images/banners/64_small.jpg' },
  //   title: 'Порадуйте пушистика'
  // },
  // {
  //   backgroundColor: '#ffca00',
  //   buttonColor: 'white',
  //   buttonTitle: 'Подробнее',
  //   color: '#404e5e',
  //   description: 'Совершите любой заказ с\xA0продуктами бренда Supermins и\xA0их\xA0стоимость вернётся к\xA0вам на\xA0счёт в\xA0виде баллов',
  //   path: `${Page.SpecialOffers}pokupayte-supermins-vygodno-41/`,
  //   picture: { src: '/images/banners/46.jpg', src2: '/images/banners/46_small.jpg' },
  //   title: 'Кешбэк 100% на\xA0добавки Supermins'
  // },
  // {
  //   backgroundColor: '#fe734a',
  //   buttonColor: 'white',
  //   buttonTitle: 'Перейти',
  //   color: 'white',
  //   description: 'Пройдите тест на\xA0300\xA0аллергенов: для правильного лечения, коррекции питания и\xA0образа жизни. Развёрнутый отчёт с\xA0рекомендациями. Сезон обострения не\xA0помеха!\n\nСкидка\xA010% до\xA09\xA0июня',
  //   path: `${Page.Products}areal-allergologicheskiy-pasport-molekulyarnaya-diagnostika-allergii-1-test-599/`,
  //   picture: { src: '/images/banners/57.jpg', src2: 'images/banners/57_small.jpg' },
  //   title: 'Жить без аллергии возможно!'
  // }
  // {
  //   backgroundColor: '#3d7b1d',
  //   buttonColor: 'white',
  //   buttonTitle: 'Подробнее',
  //   color: 'white',
  //   description: 'При покупке 2\xA0упаковок Липосомального магния вы\xA0получаете Липосомальный глюкозамин в\xA0подарок!',
  //   path: `${Page.SpecialOffers}podarki-ne-zakonchilis-38/`,
  //   picture: { src: '/images/banners/36_2.jpg', src2: '/images/banners/36_small_2.jpg' },
  //   title: 'Спасение для ваших суставов бесплатно'
  // },
  // {
  //   backgroundColor: '#fcc800',
  //   buttonColor: 'white',
  //   buttonTitle: 'Смотреть',
  //   color: '#4e301d',
  //   description: 'В\xA0честь Дня защиты детей запустили специальную категорию с\xA0детскими добавками и\xA0не\xA0только',
  //   path: constants.categories.bestForChildren.path,
  //   picture: { src: '/images/banners/59.jpg', src2: '/images/banners/59_small.jpg' },
  //   title: 'Скидки на товары для детей'
  // }
  // {
  //   backgroundColor: '#02b574',
  //   buttonColor: 'white',
  //   buttonTitle: 'Подобрать витамины',
  //   color: 'white',
  //   description: 'Пройдите тест, чтобы улучшить своё здоровье. Получите удобную схему приёма в\xA0подарок',
  //   path: Page.Tests,
  //   picture: { src: '/images/banners/32.jpg', src2: '/images/banners/32_small.jpg' },
  //   title: 'Индивидуальный подбор витаминов'
  // },
  // {
  //   backgroundColor: '#ffa37d',
  //   buttonColor: 'white',
  //   buttonTitle: 'Перейти',
  //   color: 'white',
  //   description: 'Давно хотели привести себя в\xA0форму? Сейчас самое время: Неделя спорта на\xA0iTAB! Собрали в\xA0одной категории продукты, которые помогут тренироваться эффективнее',
  //   path: constants.categories.sportWeek.path,
  //   picture: { src: '/images/banners/58.jpg', src2: '/images/banners/58_small.jpg' },
  //   title: 'Неделя спорта'
  // },
  // {
  //   backgroundColor: '#ff887c',
  //   buttonColor: 'white',
  //   buttonTitle: 'Перейти',
  //   color: 'white',
  //   description: 'Как восстановиться после майских праздников и\xA0килограммов шашлыков? Легко: объявляем Детокс-неделю на\xA0iTAB!',
  //   path: constants.categories.detoxWeek.path,
  //   picture: { src: '/images/banners/56.jpg', src2: '/images/banners/56_small.jpg' },
  //   title: 'Детокс-неделя'
  // },
  // {
  //   backgroundColor: '#4ebf3a',
  //   buttonColor: 'white',
  //   buttonTitle: 'Перейти',
  //   color: 'white',
  //   description: 'На\xA0этой неделе уделите особое внимание своему самочувствию. А\xA0помогут в\xA0этом качественные добавки, которые мы\xA0собрали в\xA0одной категории!',
  //   path: constants.categories.healthWeek.path,
  //   picture: { src: '/images/banners/55.jpg', src2: '/images/banners/55_small.jpg' },
  //   title: 'Неделя здоровья на\xA0iTAB!'
  // },
  // {
  //   backgroundColor: '#fd56a6',
  //   buttonColor: 'white',
  //   buttonTitle: 'Перейти',
  //   color: 'white',
  //   description: 'Стать красоткой на\xA0всю голову просто: объявляем неделю красоты на\xA0iTAB! Все любимые витамины и\xA0средства в\xA0одной категории',
  //   path: constants.categories.beautyWeek.path,
  //   picture: { src: '/images/banners/54.jpg', src2: '/images/banners/54_small.jpg' },
  //   title: 'Неделя красоты'
  // },
  // {
  //   backgroundColor: '#ed1651',
  //   buttonColor: 'white',
  //   buttonTitle: 'Подробнее',
  //   color: 'white',
  //   description: 'Все зарегистрированные пользователи получают бесплатную доставку от\xA0любой суммы заказа при покупке любого продукта от\xA0Swiss BORK или РНА',
  //   path: `${Page.SpecialOffers}vospolzuytes-predlozheniem-do-22-aprelya-45/`,
  //   picture: { src: '/images/banners/51.jpg', src2: '/images/banners/51_small.jpg' },
  //   title: 'Бесплатная доставка до\xA0ПВЗ Boxberry'
  // },
  // {
  //   backgroundColor: '#810a1e',
  //   buttonColor: 'white',
  //   buttonTitle: 'Подробнее',
  //   color: '#ffe378',
  //   description: 'В день рождения iTAB вкладываем золотые билеты за\xA0каждые 6000\xA0₽ в\xA0чеке. Разыгрываем добавки, сертификаты, ЗОЖ-гаджеты и\xA0тысячу других призов!',
  //   path: `${Page.SpecialOffers}otmechaem-4-goda-vmeste-s-lyubimymi-klientami-42/`,
  //   picture: { src: '/images/banners/50.jpg', src2: '/images/banners/50_small.jpg' },
  //   title: 'Отмечаем вместе!'
  // },
  // {
  //   backgroundColor: '#b87aff',
  //   buttonColor: 'white',
  //   buttonTitle: 'Хочу вкусняшки',
  //   color: 'white',
  //   description: 'И\xA0без вреда для фигуры... Собрали для вас все самое вкусное в\xA0одном месте и\xA0снизили цену на\xA0ряд десертов до\xA014\xA0апреля',
  //   path: constants.categories.sweetLife.path,
  //   picture: { src: '/images/banners/48.jpg', src2: '/images/banners/48_small.jpg' },
  //   title: 'Вот это сладкая жизнь!'
  // },
  // {
  //   backgroundColor: '#6d0077',
  //   buttonColor: 'white',
  //   buttonTitle: 'Смотреть',
  //   color: 'white',
  //   description: 'Поздравляем всех мусульман с\xA0началом священного поста. В\xA0честь праздника мы\xA0собрали продукты с\xA0сертификацией HALAL в\xA0единую категорию',
  //   path: constants.categories.halal.path,
  //   picture: { src: '/images/banners/45.jpg', src2: '/images/banners/45_small_2.jpg' },
  //   title: 'Благословенного Рамадана!'
  // }
  // {
  //   backgroundColor: '#ff6b09',
  //   buttonColor: 'white',
  //   buttonTitle: 'Смотреть',
  //   color: 'white',
  //   description: 'Началась самая сладкая неделя в\xA0этом году. Чтобы блинчики были не\xA0только вкусными, но\xA0и\xA0полезными, мы\xA0собрали для вас ПП-бакалею',
  //   path: constants.categories.pancakeWeek.path,
  //   picture: { src: '/images/banners/44.jpg', src2: '/images/banners/44_small_2.jpg' },
  //   title: 'С\xA0Масленицей!'
  // },
  // {
  //   backgroundColor: '#f00073',
  //   buttonColor: 'white',
  //   buttonTitle: 'Собрать подарок',
  //   color: 'white',
  //   description: 'Больше не\xA0нужно ломать голову, что подарить: собирайте идеальное комбо к\xA08\xA0марта из\xA0нужных добавок, безопасной косметики и\xA0любимых вкусностей',
  //   path: constants.categories.womensWeek.path,
  //   picture: { src: '/images/banners/43.jpg', src2: '/images/banners/43_small.jpg' },
  //   title: 'Неделя весны на\xA0iTAB'
  // },
  // {
  //   backgroundColor: '#ed1651',
  //   buttonColor: 'white',
  //   buttonTitle: 'Подробнее',
  //   color: 'white',
  //   description: 'Авторизуйтесь на сайте и получите бесплатную доставку до ПВЗ Boxberry',
  //   path: `${Page.SpecialOffers}schastlivaya-nedelya-pri-lyuboy-summe-zakaza-dostavka-besplatno-40/`,
  //   picture: { src: '/images/banners/42.jpg', src2: '/images/banners/42_small.jpg' },
  //   title: 'Счастливая неделя'
  // },
  // {
  //   backgroundColor: '#78b7ff',
  //   buttonColor: 'white',
  //   buttonTitle: 'Смотреть',
  //   color: 'white',
  //   description: 'Собрали идеи для презента любимому мужчине: можно поддержать здоровье, мотивировать в\xA0спорте или устроить сладкую жизнь и\xA018+ с огоньком',
  //   path: constants.categories.onFebruary23rd.path,
  //   picture: { src: '/images/banners/39.jpg', src2: '/images/banners/39_small.jpg' },
  //   title: 'В подарок защитнику'
  // },
  // {
  //   backgroundColor: '#4ebc67',
  //   buttonColor: 'white',
  //   buttonTitle: 'Подробнее',
  //   color: 'white',
  //   description: 'При покупке ДНК-теста MyExpert отчет MyNeuro в\xA0подарок до 25\xA0февраля',
  //   path: `${Page.SpecialOffers}pri-pokupke-dnk-testa-myexpert-otchet-myneuro-v-podarok-do-25-fevralya-39/`,
  //   picture: { src: '/images/banners/41.jpg', src2: '/images/banners/41_small.jpg' },
  //   title: 'Узнайте о себе всё!'
  // },
  // {
  //   backgroundColor: '#086c00',
  //   buttonColor: 'white',
  //   buttonTitle: 'Хочу чуда',
  //   color: 'white',
  //   description: 'У\xA0нас перевернулся грузовик с\xA0подарками: ловите их\xA0скорее! Мы\xA0снизили цены на\xA0более 500\xA0товаров, чтобы вы\xA0могли запастись здоровьем и\xA0хорошим настроением',
  //   path: `${Page.Categories}novogodnee-chudo-210/`,
  //   picture: { src: '/images/banners/35.jpg', src2: '/images/banners/35_small.jpg' },
  //   title: 'Сюрприз под\xA0Новый год!'
  // },
  // {
  //   backgroundColor: '#7086ff',
  //   buttonColor: 'white',
  //   buttonTitle: 'Подробнее',
  //   color: 'white',
  //   description: 'Купите 2\xA0растительных мелатонина BioExpert и\xA0получите в\xA0подарок экоферрин от\xA0Supermins',
  //   path: `${Page.SpecialOffers}darim-podarki-ot-supermins-31/`,
  //   picture: { src: '/images/banners/34.jpg', src2: '/images/banners/34_small.jpg' },
  //   title: 'Дарим Экоферрин в\xA0ваши заказы!'
  // },
  // {
  //   backgroundColor: 'black', // #a836c7
  //   buttonColor: 'gray',
  //   buttonTitle: 'Смотреть',
  //   color: 'white',
  //   description: 'Собрали все чёрные-чёрные баночки в\xA0одной категории, чтобы не\xA0бояться наступления холодов. И все полезные сладости: чтобы было, чем делиться!',
  //   path: constants.categories.halloween.path,
  //   picture: { src: '/images/banners/28.jpg', src2: '/images/banners/28_small.jpg' },
  //   title: 'Страшно качественные витамины'
  // },
  // {
  //   backgroundColor: 'black',
  //   buttonColor: 'gray',
  //   buttonTitle: 'Смотреть',
  //   color: 'white',
  //   description: 'Не\xA0пропустите «Чёрную пятницу» с\xA013 по\xA015\xA0октября!',
  //   path: `${Page.Categories}chyornaya-pyatnitsa-77/`,
  //   picture: { src: '/images/banners/27.jpg', src2: '/images/banners/27_small_2.jpg' },
  //   title: 'Распродажа, которую все ждут'
  // },
  // {
  //   backgroundColor: 'black',
  //   buttonColor: 'light-yellow',
  //   buttonTitle: 'Напомнить',
  //   color: 'white',
  //   description: 'Не\xA0пропустите «Чёрную пятницу» с\xA013 по\xA015\xA0октября!',
  //   modalWindow: CreateSubscriber,
  //   modalWindowForButton: CreateSubscriber,
  //   picture: { src: '/images/banners/27.jpg', src2: '/images/banners/27_small_2.jpg' },
  //   title: 'Распродажа, которую все ждут'
  // },
  // {
  //   backgroundColor: '#02733e',
  //   buttonColor: 'white',
  //   buttonTitle: 'Смотреть продукты',
  //   color: 'white',
  //   description: 'Запастись к\xA0школе нужно не\xA0только книжками и\xA0тетрадками, но\xA0и\xA0витаминами для крепкого здоровья. Поэтому мы\xA0снижаем цены на\xA0любимые добавки!',
  //   path: constants.categories.schoolFriday.path,
  //   picture: { src: '/images/banners/24.png', src2: '/images/banners/24.png' },
  //   title: 'Большая распродажа'
  // },
  // {
  //   backgroundColor: '#637b1b',
  //   buttonColor: 'white',
  //   buttonTitle: 'Подробнее',
  //   color: 'white',
  //   description: 'Новинку для иммунитета\xA0— витамин D3\xA0от\xA0Supermins\xA0— теперь можно получить бесплатно при покупке 2\xA0упаковок Вкусного В-комплекса',
  //   path: `${Page.SpecialOffers}kupi-2-upakovki-vkusnogo-v-kompleksa-poluchi-vitamin-d3-v-podarok-25/`,
  //   picture: { src: '/images/banners/20.jpg', src2: '/images/banners/20_small.jpg' },
  //   title: 'Заряжаемся энергией, укрепляем здоровье!'
  // },
  // {
  //   backgroundColor: '#ff9305',
  //   buttonColor: 'white',
  //   buttonTitle: 'За\xA0покупками',
  //   color: 'white',
  //   description: 'На\xA02\xA0дня снизили цены на\xA0добавки и\xA0другие классные продукты для здоровья. Спортпит, вкусняшки и\xA0косметика ждут вас!',
  //   path: constants.categories.sale1111.path,
  //   picture: { src: '/images/banners/29.jpg', src2: '/images/banners/29_small.jpg' },
  //   title: 'Большие скидки'
  // },
  // {
  //   backgroundColor: '#9a6ab8',
  //   buttonColor: 'white',
  //   buttonTitle: 'Подробнее',
  //   color: 'white',
  //   description: 'Для новеньких мы\xA0приготовили подарок: успейте до\xA03\xA0декабря!',
  //   path: `${Page.SpecialOffers}300-rubley-na-pervyy-zakaz-za-registratsiyu-na-sayte-13/`,
  //   picture: { src: '/images/banners/31.jpg', src2: '/images/banners/31_small.jpg' },
  //   title: 'Ещё не зарегистрированы?'
  // },
  // {
  //   backgroundColor: '#cc5faa',
  //   buttonColor: 'white',
  //   buttonTitle: 'Подробнее',
  //   color: 'white',
  //   description: 'Приготовили для вас подарок\xA0— нужно просто зарегистрироваться!',
  //   path: `${Page.SpecialOffers}300-rubley-na-pervyy-zakaz-za-registratsiyu-na-sayte-13/`,
  //   picture: { src: '/images/banners/23.png', src2: '/images/banners/23_small.png' },
  //   title: 'Акция для новичков'
  // },
  // {
  //   backgroundColor: 'black',
  //   buttonColor: 'gray',
  //   buttonTitle: 'Смотреть',
  //   color: 'white',
  //   description: 'Самые долгожданные дни октября. Не\xA0пропустите!',
  //   path: `${Page.Categories}chyornaya-pyatnitsa-77/`,
  //   picture: { src: '/images/banners/8.png', src2: '/images/banners/8_small.png' },
  //   title: 'Большая распродажа'
  // },
  // {
  //   backgroundColor: 'black',
  //   buttonColor: 'gray',
  //   buttonTitle: 'Напомнить',
  //   color: 'white',
  //   description: 'Самые долгожданные дни октября. Не\xA0пропустите!',
  //   modalWindowForButton: CreateSubscriber,
  //   path: `${Page.SpecialOffers}glavnoe-sobytie-oktyabrya-chernaya-pyatnitsa-16/`,
  //   picture: { src: '/images/banners/8.png', src2: '/images/banners/8_small.png' },
  //   title: 'Большая распродажа'
  // },
  // {
  //   backgroundColor: '#57b37f',
  //   buttonColor: 'gray',
  //   buttonTitle: 'Подробнее',
  //   color: 'white',
  //   description: 'Доставим заказ бесплатно до\xA0пункта выдачи Boxberry по\xA0России и\xA0СНГ',
  //   path: `${Page.SpecialOffers}besplatnaya-dostavka-bez-minimalnoy-summy-zakaza-dlya-novyh-klientov-18/`,
  //   picture: { src: '/images/banners/9.png', src2: '/images/banners/9_small.png' },
  //   title: 'Акция для новеньких'
  // },
  // {
  //   backgroundColor: '#ed1551',
  //   buttonColor: 'gray',
  //   buttonTitle: 'Подробнее',
  //   color: 'white',
  //   description: 'До\xA0пункта выдачи Boxberry по\xA0России и\xA0СНГ при заказе от\xA03500\xA0₽',
  //   path: `${Page.SpecialOffers}besplatnaya-dostavka-ot-3500-rubley-17/`,
  //   picture: { src: '/images/banners/7.png', src2: '/images/banners/7_small.png' },
  //   title: 'Бесплатная доставка'
  // },
  // {
  //   backgroundColor: '#05a56c',
  //   buttonColor: 'white',
  //   buttonTitle: 'Подробнее',
  //   color: 'white',
  //   description: 'Доставим заказ на\xA0любую сумму бесплатно до\xA0пункта выдачи Boxberry по\xA0России и\xA0СНГ',
  //   path: `${Page.SpecialOffers}besplatnaya-dostavka-bez-minimalnoy-summy-zakaza-dlya-vseh-18/`,
  //   picture: { src: '/images/banners/15.jpg', src2: '/images/banners/15_small.jpg' },
  //   title: 'Бесплатная доставка в\xA0честь Нового года'
  // },
  // {
  //   backgroundColor: '#17aa72',
  //   buttonColor: 'white',
  //   buttonTitle: 'Подробнее',
  //   color: 'white',
  //   description: 'Дарим подарок за\xA0регистрацию на\xA0сайте до\xA028\xA0февраля!',
  //   path: `${Page.SpecialOffers}300-rubley-na-pervyy-zakaz-za-registratsiyu-na-sayte-13/`,
  //   picture: { src: '/images/banners/17.png', src2: '/images/banners/17_small.png' },
  //   title: 'Акция для новичков'
  // },
  // {
  //   backgroundColor: '#209265',
  //   buttonColor: 'white',
  //   buttonTitle: 'Смотреть продукты',
  //   color: 'white',
  //   description: 'Дарим праздничные цены на\xA0специальную категорию турецких добавок до\xA031\xA0марта',
  //   path: `${Page.Categories}happy-birthday-191/`,
  //   picture: { src: '/images/banners/18.png', src2: '/images/banners/18_small.png' },
  //   title: 'Отмечаем все!'
  // },
  // {
  //   backgroundColor: '#02b568',
  //   buttonColor: 'white',
  //   buttonTitle: 'Подробнее',
  //   color: 'white',
  //   description: 'При заказе от\xA03000\xA0₽ дарим вам доставку до\xA0любого пункта выдачи заказов по\xA0России и\xA0СНГ',
  //   path: `${Page.SpecialOffers}snizhena-stoimost-zakaza-dlya-polucheniya-besplatnoy-dostavki-26/`,
  //   picture: { src: '/images/banners/21.png', src2: '/images/banners/21_small.png' },
  //   title: 'Снизили стоимость заказа для бесплатной доставки'
  // },
  // {
  //   backgroundColor: '#ff4ede',
  //   buttonColor: 'white',
  //   buttonTitle: 'Подробнее',
  //   color: 'white',
  //   description: 'Да, вашему котику или пёсику тоже нужны витамины, чтобы быть быстрым и\xA0энергичным, а\xA0шерсть лоснилась и\xA0блестела. Поэтому всю неделю\xA0— скидки на\xA0добавки для животных!',
  //   path: `${Page.SpecialOffers}hvostataya-nedelya-30-na-dobavki-dlya-koshek-i-sobak-28/`,
  //   picture: { src: '/images/banners/22.png', src2: '/images/banners/22_small.png' },
  //   title: 'Чтобы пушистые друзья были здоровы'
  // },
  // {
  //   backgroundColor: '#08c379',
  //   buttonColor: 'white',
  //   buttonTitle: 'Пройти тест',
  //   color: 'white',
  //   description: 'Чем поддержать организм современному человеку? Пройдите тест, и\xA0мы\xA0порекомендуем индивидуальный набор качественных БАД',
  //   path: `${Page.Tests}podbor-bad-1/`,
  //   picture: { src: '/images/sections/quiz.jpg', src2: '/images/sections/quiz_2.jpg' },
  //   smallDescription: 'Всего 20\xA0вопросов. Займёт 5\xA0минут.',
  //   title: 'Тест по\xA0подбору БАД'
  // }
]

export const banners2: Banner[] = [
  {
    backgroundColor: '#2a534c',
    buttonColor: 'white',
    buttonTitle: 'Перейти',
    color: 'white',
    description: 'Собрали в\xA0одну категорию всю халяль-продукцию: от\xA0любимых витаминов до\xA0полезных сладостей. Все позиции имеют необходимую сертификацию',
    path: constants.categories.halal.path,
    picture: { src: '/images/banners/49.jpg', src2: '/images/banners/49_small.jpg' },
    title: 'Халяль-продукты в\xA0одном месте!'
  },
  {
    backgroundColor: '#ffcd00',
    buttonColor: 'white',
    buttonTitle: 'Подробнее',
    color: '#404e5e',
    description: 'Приглашаем интегративных специалистов к\xA0сотрудничеству. Рекомендуйте наши продукты и\xA0получайте бонусы с\xA0каждой покупки!',
    path: Page.Partner,
    picture: { src: '/images/banners/37.jpg', src2: '/images/banners/37_small.jpg' },
    title: 'Программа лояльности'
  },
  {
    backgroundColor: '#feda7c',
    buttonColor: 'white',
    buttonTitle: 'Подробнее',
    color: '#404e5e',
    description: 'Поможем прийти к\xA0желаемым результатам, будь\xA0то снижение веса, возвращение энергии или укрепление иммунитета',
    path: `${Page.Categories}konsultatsii-187/`,
    picture: { src: '/images/banners/40.jpg', src2: '/images/banners/40_small.jpg' },
    title: 'Консультации специалистов'
  },
  {
    backgroundColor: '#2e7ec4',
    buttonColor: 'white',
    buttonTitle: 'Смотреть',
    color: 'white',
    description: 'Создали для вас эксклюзивную категорию с\xA0продуктами для защиты от\xA0вирусов и\xA0поддержки иммунитета',
    path: constants.categories.againstSars.path,
    picture: { src: '/images/banners/33_2.jpg', src2: '/images/banners/33_small.jpg' },
    title: 'Заканчиваем болеть!'
  }
  // {
  //   backgroundColor: '#b5427f',
  //   buttonColor: 'white',
  //   buttonTitle: 'Посмотреть исследования',
  //   color: 'white',
  //   description: 'Исследование показывает высокое среднее увеличение ВИТАМИНА D3 в\xA0крови после приема 1\xA0банки 30\xA0мл\n',
  //   path: `${Page.Products}imuali-mitsellyarnyy-vitamin-d3-12500-me-k2-mk-7-super-osteo-complex-zhidkost-30-ml-1752/?utm_source=itab&utm_medium=banner&utm_campaign=imuali-super-osteo-complex`,
  //   picture: { src: '/images/banners/78.jpg', src2: '/images/banners/78_small.jpg' },
  //   title: 'Imuali Super Osteo'
  // },
  // {
  //   backgroundColor: '#00b02b',
  //   buttonColor: 'white',
  //   buttonTitle: 'Установить',
  //   color: 'white',
  //   description: 'Ура, у\xA0iTAB появилось приложение! Теперь оформлять покупки любимых витаминов и\xA0следить за\xA0состоянием здоровья стало еще\xA0удобнее',
  //   path: 'https://itab.pro/app/',
  //   picture: { src: '/images/banners/77.jpg', src2: '/images/banners/77_small.jpg' },
  //   title: 'Скачивайте уже сейчас'
  // },
  // {
  //   backgroundColor: '#ffdb69',
  //   buttonColor: 'white',
  //   buttonTitle: 'Купить',
  //   color: '#404e5e',
  //   description: '✔ С пептидами.\n'
  //     + '✔ Рекомендовано трихологами.\n'
  //     + '✔ Активирует рост новых волос.\n'
  //     + '✔ Эффективно при любом типе выпадения волос.',
  //   path: `${Page.Brands}rinfoltil-324/`,
  //   picture: { src: '/images/banners/76.jpg', src2: '/images/banners/76_small.jpg' },
  //   title: 'Натуральная липосомальная косметика'
  // },
  // {
  //   backgroundColor: '#30673d',
  //   buttonColor: 'white',
  //   buttonTitle: 'Купить',
  //   color: 'white',
  //   description: '✔ Эффективные формулы\n'
  //     + '✔ Разработаны врачами в\xA0Швейцарии\n'
  //     + '✔ Безопасный состав',
  //   path: `${Page.Brands}mindly-137/`,
  //   picture: { src: '/images/banners/66.jpg', src2: '/images/banners/66_small.jpg' },
  //   title: 'MINDLY\xA0— витамины премиального качества'
  // },
  // {
  //   backgroundColor: '#14a0a7',
  //   buttonColor: 'white',
  //   buttonTitle: 'Купить',
  //   color: 'white',
  //   description: '✔ Устраняет выпадение волос любой этиологии\n'
  //     + '✔ Активирует рост новых волос\n'
  //     + '✔ Рекомендуется врачами-трихологами\n'
  //     + '✔ LIPOSOME TECHNOLOGY FREEZE-DRIED',
  //   path: `${Page.Brands}rinfoltil-324/`,
  //   picture: { src: '/images/banners/63.jpg', src2: '/images/banners/63_small.jpg' },
  //   title: 'RINFOLTIL\xA0— секрет красивых и\xA0густых волос'
  // },
  // {
  //   backgroundColor: '#56a3cf',
  //   buttonColor: 'white',
  //   buttonTitle: 'Купить',
  //   color: 'white',
  //   description: '✔ экстракт печени командорского кальмара;\n' +
  //     '✔ чистый тюлений жир из\xA0глубин Охотского моря;\n' +
  //     '✔ пигмент тихоокеанского морского ежа.',
  //   path: `${Page.Brands}mindly-137/`,
  //   picture: { src: '/images/banners/30.jpg', src2: '/images/banners/30_small.jpg' },
  //   title: 'Премиальные источники здоровья:'
  // },
  // {
  //   backgroundColor: '#00bc7d',
  //   buttonColor: 'white',
  //   buttonTitle: 'Узнать больше',
  //   color: 'white',
  //   description: 'По адресу: ТРЦ «Город Лефортово», Москва, ш. Энтузиастов, д.\xA012, к.\xA02',
  //   path: Page.Offline,
  //   picture: { src: '/images/banners/19.png', src2: '/images/banners/19_small.png' },
  //   title: 'Встречаемся в\xA0офлайне!'
  // },
  // {
  //   backgroundColor: '#eea8ba',
  //   buttonColor: 'white',
  //   buttonTitle: 'Купить',
  //   color: 'white',
  //   description: 'Уникальный натуральный препарат:\n' +
  //     '✔ Экзопротектор\n' +
  //     '✔ Иммунопротектор\n' +
  //     '✔ Anti-Age',
  //   path: `${Page.Products}wow-melanin-zhidkost-50-ml-727/`,
  //   picture: { src: '/images/banners/12.png', src2: '/images/banners/12_small.png' },
  //   title: 'WOW МЕЛАНИН\xA0— функциональный напиток на\xA0основе клеточного сока пихты сибирской'
  // },
  // {
  //   backgroundColor: '#2f3186',
  //   buttonColor: 'white',
  //   buttonTitle: 'Смотреть все',
  //   color: 'white',
  //   description: 'Ура! На\xA0iTAB завезли эксклюзив\xA0— продукты турецкого производства Swiss BORK в\xA0эффективных дозировках',
  //   path: `${Page.Products}?brandId=181`,
  //   picture: { src: '/images/banners/14.jpg', src2: '/images/banners/14_small.jpg' },
  //   title: 'SWISS BORK\xA0— добавки, которые вы\xA0ждали'
  // },
  // {
  //   backgroundColor: '#a8d0e7',
  //   buttonColor: 'white',
  //   buttonTitle: 'Купить',
  //   color: 'white',
  //   description: '✔ НАТУРАЛЬНЫЙ СОСТАВ\n' +
  //     '✔ ПРЕМИАЛЬНОЕ СЫРЬЁ\n' +
  //     '✔ БИОДОСТУПНОСТЬ ДО 100%',
  //   path: `${Page.Products}?brandId=40`,
  //   picture: { src: '/images/banners/16.png', src2: '/images/banners/16_small.png' },
  //   title: 'Липосомальные витамины\xA0— эффективная поддержка вашего здоровья!'
  // },
  // {
  //   backgroundColor: '#08c379',
  //   buttonColor: 'white',
  //   buttonTitle: 'Смотреть все',
  //   color: 'white',
  //   description: 'Продукты собственного производства входят в\xA0топ-10 самых продаваемых товаров на\xA0iTAB. Мы\xA0создаём эффективные формулы для здоровья, долголетия и\xA0красоты!',
  //   path: `${Page.Categories}nashi-produkty-183/`,
  //   picture: { src: '/images/banners/3.png', src2: '/images/banners/3_small.png' },
  //   title: 'Собственное производство iTAB'
  // },
  // {
  //   backgroundColor: '#938c85',
  //   buttonColor: 'white',
  //   buttonTitle: 'Смотреть видео',
  //   color: 'white',
  //   description: 'Приглашаем вас в\xA0офлайн-пространство iTAB на\xA0ВДНХ. Это выставка качественных добавок, кафе с\xA0полезными десертами, семинары и\xA0лекции о\xA0здоровом образе жизни и\xA0сообщество единомышленников',
  //   modalWindow: TerritoryOfHealth,
  //   picture: { src: '/images/banners/6.jpg', src2: '/images/banners/6_small.jpg' },
  //   smallDescription: 'Адрес: проспект Мира, 119с457.',
  //   title: 'Территория здоровья iTAB'
  // }
]

export const bannersForDistributor: Banner[] = [
  {
    backgroundColor: '#00bc7d',
    buttonColor: 'white',
    buttonTitle: 'Оставить заявку',
    color: 'white',
    modalWindow: CreateDealerApplication,
    picture: { src: '/images/pages/distributor/banners/1.jpg', src2: '/images/pages/distributor/banners/1.jpg' },
    title: 'Айтаб\xA0— крупнейший дистрибьютор качественных БАД'
  }
]

// export const bannersForTests: Banner[] = [
//   {
//     backgroundColor: '#fdf00a',
//     buttonColor: 'white',
//     buttonTitle: 'Пройти тест',
//     color: '#404e5e',
//     description: 'Пройдите тест, чтобы оценить имеющиеся в\xA0организме вашего ребенка дисбалансы. Получите персональные рекомендации, как ему вырасти здоровым и\xA0активным',
//     path: `${Page.Tests}zdorove-rebyonka-3/`,
//     picture: { src: '/images/banners/13.jpg', src2: '/images/banners/13_small.jpg' },
//     smallDescription: 'Прохождение займёт 4—5\xA0минут',
//     title: 'Тест на\xA0оценку состояния ребенка'
//   },
//   {
//     backgroundColor: '#80e7ff',
//     buttonColor: 'white',
//     buttonTitle: 'Пройти тест',
//     color: '#404e5e',
//     description: 'Чтобы избежать губительного воздействия стресса на\xA0организм, научитесь управлять\xA0им. Пройдите тест, получите подборку добавок и\xA0персональные рекомендации по\xA0работе со\xA0стрессом',
//     path: `${Page.Tests}upravlenie-stressom-2/`,
//     picture: { src: '/images/banners/4.png', src2: '/images/banners/4_small.png' },
//     smallDescription: 'Прохождение займёт 7—8\xA0минут',
//     title: 'Тест на\xA0уровень стресса'
//   }
// ]
